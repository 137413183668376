<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      :showAdd="$hasAccess('market.gift/add')"
      addName="新增充电度数优惠"
      @getList="getList"
      @clearSearch="clearSearch"
      @addItemInTable="addOne"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-select
          v-model="searchFormData.status"
          placeholder="按状态查询"
          clearable
          size="small"
          style="width: 140px"
        >
          <el-option
            label="有效"
            :value="10"
          >
          </el-option>
          <el-option
            label="失效"
            :value="20"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="gift_ele"
        title="累计充电度数"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="gift_discount"
        title="服务费优惠"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="status"
        title="状态"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.status[row.status]||'未知状态'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        title="操作"
        align="center"
        width="160"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            plain
            @click="editOne(row)"
            v-if="$hasAccess('market.gift/edit')"
          >编辑</el-button>
          <el-button
            size="small"
            plain
            @click="deleteOne(row)"
            v-if="$hasAccess('market.gift/del')"
          >删除</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 新增 -->
    <el-dialog
      title="新增充电度数优惠"
      :visible.sync="addDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="addDialogFormData"
        :rules="rules"
        ref="addForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
                label="累计充电度数"
                prop="gift_ele"
        >
          <el-input-number
                  v-model="addDialogFormData.gift_ele"
                  controls-position="right"
                  :min="0"
                  :step="100"
                  :precision="2"
                  style="width: 60%;"
          ></el-input-number><span style="color: red;margin-left: 10px;">度</span>
        </el-form-item>
        <el-form-item
                label="服务费折扣"
                prop="gift_discount"
        >
          <el-input-number
                  v-model="addDialogFormData.gift_discount"
                  controls-position="right"
                  :min="0"
                  :step="0.1"
                  :precision="1"
                  style="width: 60%;"
          ></el-input-number><span style="color: red;margin-left: 10px;">折</span>
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-radio-group v-model="addDialogFormData.status">
            <el-radio :label="10">有效</el-radio>
            <el-radio :label="20">失效</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveAdd()"
          v-if="$hasAccess('market.gift/add')"
        >保 存
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title="编辑充电度数优惠"
      :visible.sync="editDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="editDialogFormData"
        :rules="rules"
        ref="editForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
                label="累计充电度数"
                prop="gift_ele"
        >
          <el-input-number
                  v-model="editDialogFormData.gift_ele"
                  controls-position="right"
                  :min="0"
                  :step="100"
                  :precision="2"
                  style="width: 60%;"
          ></el-input-number><span style="color: red;margin-left: 10px;">度</span>
        </el-form-item>
        <el-form-item
                label="服务费折扣"
                prop="gift_discount"
        >
          <el-input-number
                  v-model="editDialogFormData.gift_discount"
                  controls-position="right"
                  :min="0"
                  :step="0.1"
                  :precision="1"
                  style="width: 60%;"
          ></el-input-number><span style="color: red;margin-left: 10px;">折</span>
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-radio-group v-model="editDialogFormData.status">
            <el-radio :label="10">有效</el-radio>
            <el-radio :label="20">失效</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveEdit()"
          :disabled="editDisabled"
          v-if="$hasAccess('market.gift/edit')"
        >保 存 编 辑
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'market-gift-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '充电度数优惠',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      types: {
        status: {
          10: '有效',
          20: '失效'
        }
      },
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      // 规则
      rules: {
        gift_ele: [
          { required: true, message: '请输入优惠度数', trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'change' }
        ],
        gift_discount: [
          { required: true, message: '请输入折扣', trigger: 'change' }
        ]
      },
      // 编辑
      editDialogShow: false,
      editDialogFormData: {},
      editDialogFormOriData: {},
      editNeedData: ['gift_id', 'gift_ele', 'gift_discount','status']
    }
  },
  computed: {
    editDisabled () {
      for (let item of this.editNeedData) {
        if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
          return false
        }
      }
      return true
    }
  },
  mounted () {
  },
  activated () {
  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Market.GetGiftList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {}
    },
    // 重置form
    resetFormData () {
      this.addDialogFormData = {
        status: 10,
        gift_model: 20,
        gift_type: 10
      }
      this.editDialogFormData = {}
      this.editDialogFormOriData = {}
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
    },
    // 新增
    addOne () {
      this.resetFormData()
      this.addDialogShow = true
    },
    // 编辑
    async editOne (row) {
      this.resetFormData()
      const params = {
        token: this.$store.state.user.token,
        gift_id: row.gift_id
      }
      const res = await this.$api.Market.GetGiftDetail(params)
      if (res) {
        const { gift_id, gift_ele, status, gift_discount } = res
        this.editDialogFormData = { gift_id, gift_ele, status, gift_discount }
        this.editDialogFormOriData = { gift_id, gift_ele, status, gift_discount }
      }
      this.editDialogShow = true
    },
    // 删除
    deleteOne (row) {
      this.$confirm('确定要删除该优惠吗', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          gift_id: row.gift_id
        }
        await this.$api.Market.DeleteGift(params)
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.$refs.xTable.refreshTable()
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
    },
    // 新增保存
    saveAdd () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.addDialogFormData)
          }
          await this.$api.Market.AddGift(params)
          this.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    },
    // 编辑保存
    saveEdit () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.editDialogFormData)
          }
          await this.$api.Market.EditGift(params)
          this.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
